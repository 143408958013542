<template>
    <div align="center" v-if="is_mounted">
        <br>
        <div align="center">
            <span class="text-h4">Modifica dati contratto</span>
            <hr>
        </div>

        <div class="row justify-center  q-px-md">
            
            <div class="col-12 col-md-5 q-px-sm" align="left">
                
                <fieldset>
                    <legend>Dati della pratica</legend>

                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="domanda">Numero interno: </td>
                                <td class="risposta">{{ pratica.numero }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Numero: </td>
                                <td class="risposta">{{ pratica.numero_pratica }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Cliente: </td>
                                <td class="risposta">{{ cliente.cognome }} {{ cliente.nome }} </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    
                </fieldset>
                <br>
                <div class="text-h5" align="left">Dati economici</div>
                <br>
                <div  v-for="(dato_economico, index) in get_dati_economici"
                            v-bind:key="index"
                            v-bind="dato_economico">
                    <q-input
                        type="number"
                        class="q-mb-sm"
                        dense
                        v-model="dato_economico.importo"
                        outlined                        
                        :label="dato_economico.tipo"
                        step="1"
                    >
                        <template v-slot:append>
                            Euro
                        </template>

                    </q-input>
                </div>
            </div>
              
            <div class="col-12 col-md-5 q-px-sm" align="left">
                <div class="text-h5" align="left">Dati del Contratto</div>
                <br>
                <q-input dense outlined readonly v-model="dati_contratto_modificato.prodotto_id" label="Tipo di contratto" class="q-mb-sm" /> 
                <q-input dense outlined readonly v-model="dati_contratto_modificato.compagnia" label="Impresa di assicurazione" class="q-mb-sm" /> 
                <q-input outlined
                    dense
                    class="q-mb-sm"
                    error-message="Il Numero di polizza della Compagnia deve essere inserito"
                    v-model="dati_contratto_modificato.numero_polizza_compagnia"
                    label="Numero di Polizza Compagnia"
                />

                <template v-if="!tariffa_esistente">
                    <q-select outlined
                        class="q-mb-sm"
                        dense
                        error-message="Il prodotto deve essere valorizzato"
                        v-model="prodotto_da_catalogo"
                        :options="elenco_prodotti"
                        label="Prodotto a catalogo"
                        name="prodotto_catalogo"
                        virtual-scroll-slice-size="0"
                        virtual-scroll-item-size="0"
                        @input="onSelectProdotto()"
                     />
                    
                    <q-input outlined
                        class="q-mb-sm"
                        dense
                        v-model="dati_contratto_modificato.fornitore"
                        label="Fornitore"
                        readonly
                     />

                    <q-input outlined
                        class="q-mb-sm"
                        v-model="dati_contratto_modificato.tariffa"
                        label="Tariffa"
                        readonly 
                        dense
                    />

                </template>

                <q-input outlined
                    class="q-mb-sm"
                    v-model="decorrenza_contratto"
                    mask="##/##/####"
                    dense
                    label="Data di decorrenza"
                >
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="decorrenza_contratto"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>

                <q-input outlined
                    class="q-mb-sm"
                    v-model="scadenza_contratto"
                    mask="##/##/####"
                    dense
                    label="Data di scadenza contrattuale"
                >
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="scadenza_contratto"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>

                <q-input outlined
                    class="q-mb-sm"
                    v-model="decorrenza_quietanza"
                    mask="##/##/####"
                    dense
                    label="Data di decorrenza di rata"
                >
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="decorrenza_quietanza"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>

                <q-input outlined
                    class="q-mb-sm"
                    v-model="scadenza_quietanza"
                    mask="##/##/####"
                    dense
                    label="Data di scadenza di rata"
                >
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="scadenza_quietanza"
                                mask="dd/MM/yyyy">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
            </div>
        </div>

        <div align="center">
            <hr>
            
            <div class="row justify-center">
                <div class="col-md-4" align="center">
                    <QQButton label="ANNULLA MODIFICHE" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                <div class="col-md-4" align="center">
                    <QQButton label="REGISTRA"
                        color="blue-grey"
                        icon="mdi-arrow-right-bold"
                        size="md"
                        icon_align="right"
                        @click.native="onRegistraClicked()"
                    />
                </div>
            </div>

            <br><br><br><br><br><br>
        </div>

    </div>
</template>

<script>

import { mapFields} from 'vuex-map-fields';
import { mapActions } from 'vuex';
import commonLib from "@/libs/commonLib";
import QQButton from "@/components/QQButton.vue";

export default {
    name: "ModificaDatiContratto",
    components: {
        QQButton
    },
    data() {
        return {
            is_loading: true,
            is_mounted: false,
            prodotto_da_catalogo: "",
            tariffa_esistente: false,
            dati_contratto_modificato: {},
            dati_quietanza_modificato: {},
            dati_economici_modificato: [],
            elenco_compagnie: [],
            elenco_tariffe: [],
            elenco_prodotti: [],
            decorrenza_contratto: "",
            scadenza_contratto: "",
            decorrenza_quietanza: "",
            scadenza_quietanza: ""
        }
    },
    methods: {
        ...mapActions({
            fetchElencoCompagnie: "gestioneCatalogoProdotti/fetchElencoCompagnie",
            fetchElencoTariffe: "gestioneCatalogoProdotti/fetchElencoTariffe",
            fetchCatalogoProdottiByIDSettore: "gestioneCatalogoProdotti/fetchCatalogoProdottiByIDSettore",
            fetchElencoEmittenti: "gestioneCatalogoProdotti/fetchElencoEmittenti",
            RegistraDatiContrattoFattura: "gestioneFatture/RegistraDatiContrattoFattura"
        }),
        onSelectProdotto() {
            let codici = this.prodotto_da_catalogo.value.split(" / ");
            let codice_esteso = codici[1];
            if (codice_esteso.length === 4) {
                let start = codice_esteso.substr(0,1);
                let cod   = "0"+codice_esteso.substr(1,codice_esteso.length - 1);
                codice_esteso = start + cod;
            }

            var cerca_fornitore = this.elenco_fornitori.find(f => {
                return f.label.includes(codice_esteso);
            });

            if (commonLib.isEmpty(cerca_fornitore)) {
                alert("Fornitore non definito");
                this.tariffa   = "";
                this.fornitore = "";
                return;
            }

            this.dati_contratto_modificato.fornitore = cerca_fornitore.value;
            this.dati_contratto_modificato.compagnia = this.prodotto_da_catalogo.nome_breve;
        },
        AggiustaFormatoDate(data) {
            console.log("data:",data);

            data = (data+"").substring(0,10);
            var elementi = data.split("-");
            console.log("elementi:",elementi);

            if (commonLib.isEmpty(elementi[1])) return data;

            return elementi[2]+"/"+elementi[1].padStart(2,'0')+"/"+elementi[0].padStart(2,'0'); 
        },
        AggiustaFormatoDateForDb(data) {
            //console.log("data:",data);
            data = (data+"").substring(0,10);
            var elementi = data.split("/");

            console.log("elementi for DB:",elementi);

           // if (commonLib.isEmpty(elementi[1])) return data;

            console.log("data modificata for DB:",elementi[2]+"-"+elementi[1].padStart(2,'0')+"-"+elementi[0].padStart(2,'0'));

            return elementi[2]+"-"+elementi[1].padStart(2,'0')+"-"+elementi[0].padStart(2,'0')+"T00:00:00";
        },
        onRegistraClicked() {
            this.$q.dialog({
                title: 'Attenzione',
                html: true,
                message: "Procedo con la modifica dei dati del Contratto?",
                cancel: {
                    focus: true,
                    flat: false,
                    label: "No",
                    color: "primary"
                },
                ok: {
                    color: "secondary",
                    flat: false,
                    label: "Si, procedi"
                },
            }).onOk(async () => {
                this.contratto = this.dati_contratto_modificato;
                this.quietanza = this.dati_quietanza_modificato;
                this.dati_economici = this.dati_economici_modificato;

                this.contratto.decorrenza = this.AggiustaFormatoDateForDb(this.decorrenza_contratto);
                this.contratto.scadenza   = this.AggiustaFormatoDateForDb(this.scadenza_contratto);
                this.quietanza.decorrenza = this.AggiustaFormatoDateForDb(this.decorrenza_quietanza);
                this.quietanza.scadenza = this.AggiustaFormatoDateForDb(this.scadenza_quietanza);

                console.log("contratto:",this.contratto);

                await this.RegistraDatiContrattoFattura({
                    guidPratica: this.pratica.numero,
                    _contratto: this.contratto,
                    _quietanza: this.quietanza,
                    _datiEconomici: this.dati_economici
                });
                
                console.log("contratto:",this.contratto);
                console.log("quietanza:",this.quietanza);

                this.$router.push({name : "Fatture.SchedaFattura"});
            });
        },
        onIndietroClicked() {
            this.$router.push({name : "Fatture.SchedaFattura"});
        }
    },
    computed: {
        ...mapFields("gestioneFatture", {
            cliente: "cliente",
            pratica: "pratica",
            contratto: "contratto",
            quietanza: "quietanza",
            dati_economici: "dati_economici"
        }),
        get_dati_economici() {
            return this.dati_economici.filter(de => {
                return de.da_sommare === true;
            })
        }
    },
    
    async mounted() {
        this.dati_contratto_modificato = this.contratto;
        this.dati_quietanza_modificato = this.quietanza;
        this.dati_economici_modificato = this.dati_economici;

        console.log("contratto prima:",this.contratto);

        // Aggiusta il formato delle date
        this.decorrenza_contratto = this.AggiustaFormatoDate(this.contratto.decorrenza);
        this.scadenza_contratto   = this.AggiustaFormatoDate(this.contratto.scadenza);
        this.decorrenza_quietanza = this.AggiustaFormatoDate(this.quietanza.decorrenza);
        this.scadenza_quietanza   = this.AggiustaFormatoDate(this.quietanza.scadenza);

        this.elenco_compagnie = await this.fetchElencoCompagnie(false);
        this.elenco_tariffe   = await this.fetchElencoTariffe();
        var tariffa = this.contratto.tariffa;
        var tariffa_esistente = this.elenco_tariffe.find(t => {
            return t.value === tariffa;
        });

        this.tariffa_esistente = !commonLib.isEmpty(tariffa_esistente);

        if (!this.tariffa_esistente) {
            this.elenco_prodotti = await this.fetchCatalogoProdottiByIDSettore({ prodotto_id: this.contratto.prodotto_id, ValueAsLabel: false});
            this.elenco_fornitori = await this.fetchElencoEmittenti();
        }
        

        console.log("tariffa esistente:",this.tariffa_esistente);
        console.log("contratto:",this.contratto);

        this.is_mounted = true;
    }
}

</script>

<style scoped>
    td.domanda {
        text-align: left;
        font-style: italic;
        border-bottom: 1px dashed #c0c0c0;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        font-weight: bold;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>